import { workStatusMessages } from '../../../interfaces';
import { WorkStatus } from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';
import { Badge } from '@amzn/awsui-components-react';
import React from 'react';

export const WorkStatusBadge = (props: { status: WorkStatus }) => (
  <Badge color={workStatusMessages[props.status].color}>
    Status: {workStatusMessages[props.status].text}
  </Badge>
);
