import {
  VirtualSmileyConferenceToolClient,
  ServiceInputTypes,
  ServiceOutputTypes,
  $Command,
  VirtualSmileyConferenceToolClientResolvedConfig,
  InvalidInputException,
  AccessDeniedException,
  TcxRuntimeException,
  RequestThrottledException,
  MethodNotAllowedException,
  ResourceConflictException,
  ResourceNotFoundException,
} from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';
import { getApiInvokeUrl } from './ConferencePages/conferenceInfo/utils';
import { MiddlewareStack } from '@smithy/types';

type ExpectedErrors =
  | InvalidInputException
  | AccessDeniedException
  | TcxRuntimeException
  | RequestThrottledException
  | MethodNotAllowedException
  | ResourceConflictException
  | ResourceNotFoundException;

// Initialize VirtualSmileyConferenceToolClient
export const client = new VirtualSmileyConferenceToolClient({
  endpoint: getApiInvokeUrl(),
  region: 'us-east-1',
  credentials: {
    accessKeyId: 'elephant',
    secretAccessKey: 'stockings',
  },
});

// Middleware to add headers
export const addHeaderMiddleware = (
  client: {
    middlewareStack: MiddlewareStack<ServiceInputTypes, ServiceOutputTypes>;
  },
  token: string
): void =>
  client.middlewareStack.add(
    (next) => async (args) => {
      if (
        args.request &&
        typeof args.request === 'object' &&
        'headers' in args.request
      ) {
        Object.assign(
          (args.request as { headers: Record<string, string> }).headers,
          {
            'x-lambda-authorizer-token': token,
          }
        );
      }
      return next(args);
    },
    {
      step: 'build',
      name: 'headersMiddleware',
      tags: ['lambda-auth'],
    }
  );

const isExpectedError = (err: any): err is ExpectedErrors => {
  return (
    err instanceof InvalidInputException ||
    err instanceof AccessDeniedException ||
    err instanceof TcxRuntimeException ||
    err instanceof RequestThrottledException ||
    err instanceof MethodNotAllowedException ||
    err instanceof ResourceConflictException ||
    err instanceof ResourceNotFoundException
  );
};

type SecondTypeOf<T> = T extends $Command<infer _, infer R, infer __>
  ? R
  : never;
type CommandReturns<T> = Promise<SecondTypeOf<T>>;

export const callApi = async <
  C extends $Command<any, any, VirtualSmileyConferenceToolClientResolvedConfig>
>(
  command: C,
  token: string
): Promise<CommandReturns<C>> => {
  command.middlewareStack.removeByTag('lambda-auth');
  addHeaderMiddleware(command, token);
  try {
    const response: CommandReturns<C> = await client.send(command);
    return response;
  } catch (err) {
    if (isExpectedError(err)) {
      throw {
        statusCode: err.$metadata.httpStatusCode,
        message: err.message,
      };
    } else {
      throw {
        statusCode: 0,
        message: 'Unknown error occurred',
      };
    }
  }
};
