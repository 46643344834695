import { FormField } from '@amzn/awsui-components-react';
import { getEnvironment } from '../../authentication/config';
import { TranslatedWorkStatus } from '../../interfaces';
import {
  PackageBuildInfo,
  BuildRequestStatus,
  WorkStatus,
} from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';

export function getListOfPackages(packages: PackageBuildInfo[] | undefined) {
  if (!packages || packages.length === 0) {
    return ['-'];
  }

  if (packages.length === 1) {
    return [packages[0].packageName]; // Return the package name as an array with one element
  }

  return packages.map((packageInfo) => packageInfo.packageName).join(', ');
}

export const mapWorkStatusToReadableString = (
  workStatus: WorkStatus
): string => {
  return TranslatedWorkStatus[workStatus]
    ? TranslatedWorkStatus[workStatus]
    : workStatus;
};

export const mapBuildRequestStatusToReadableString = (
  status: BuildRequestStatus
): string => {
  return BuildRequestStatus[status]
    ? BuildRequestStatus[status]
    : status.toString();
};

export function displayDueDate(dueDate: number, overdue?: boolean) {
  const formattedDate = convertToPST(dueDate);
  if (overdue) {
    return <FormField errorText={formattedDate}></FormField>;
  } else {
    return <span>{formattedDate}</span>;
  }
}

export function displayPersonAssigned(personAssignment: string | undefined) {
  if (personAssignment) {
    return <span>{personAssignment}</span>;
  } else {
    return <FormField errorText='Not Found'></FormField>;
  }
}

export function displayBuildStatus(buildStatus: string | undefined) {
  if (buildStatus === 'Failed') {
    return <FormField errorText={buildStatus}></FormField>;
  } else {
    return <span>{buildStatus}</span>;
  }
}
// TODO: add stage and API endpoints to context
export function getApiInvokeUrl() {
  const stage = getEnvironment();
  if (stage === 'alpha') {
    return 'https://ugfwnqk6p0.execute-api.us-east-1.amazonaws.com/alpha';
  }
  return 'https://pk95uw7zmg.execute-api.us-east-1.amazonaws.com/integ';
}

const { hostname } = window.location;
export const isProd = () => window.location.hostname.startsWith('prod.');

export const isDevDesk = () =>
  hostname.startsWith('dev-dsk-') || hostname.endsWith('.aka.corp.amazon.com');

export const isLocalHost = () => hostname === 'localhost';

export const isDevOrLocal = () => isLocalHost() || isDevDesk();

export function getRandomIntInRange(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min) + min);
}

export function convertToPST(
  epochDate: Date | EpochTimeStamp | undefined,
  accurateReading?: boolean
) {
  let result = '-';

  if (epochDate) {
    let date = epochDate;
    if (typeof date === 'number') date = new Date(date * 1000);

    let formattedDate;

    if (accurateReading) {
      formattedDate = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Los_Angeles',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        // second: '2-digit',
      }).format(date);
    } else {
      formattedDate = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Los_Angeles',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(date);
    }
    result = formattedDate + ' PST';
  }

  return result;
}
