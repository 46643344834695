import {
  Box,
  Button,
  Header,
  SpaceBetween,
  Container,
  FormField,
  Input,
  ColumnLayout,
  Flashbar,
  TokenGroup,
  TokenGroupProps,
} from '@amzn/awsui-components-react/polaris';
import { useState, useEffect, useContext } from 'react';
import {
  LaunchTask,
  Action,
  UpdatePermissionsCommand,
  UpdatePermissionsCommandOutput,
  UpdatePermissionsCommandInput,
} from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';
import { useAuthState } from '../../../authentication/context';
import { generateFlashBarItem } from './editEditorViewSection';
import { callApi } from '../../../client';
import { PermissionsContext } from './permissionscontext';
import { NoTokenError } from '../../../errors/NoTokenError';

interface editProps {
  launchTaskDetail: LaunchTask;
}

export const ModificationView = ({ launchTaskDetail }: editProps) => {
  const {
    readPermissions,
    writePermissions,
    updateReadPermissions,
    updateWritePermissions,
    notifications,
    setNotifications,
  } = useContext(PermissionsContext);
  const readerId = 'updateReaderMsg';
  const readerItem = generateFlashBarItem(readerId);
  readerItem.onDismiss = () =>
    setNotifications((items) =>
      items.filter((item) => item.id !== 'updateReaderMsg')
    );
  readerItem.type = 'success';
  readerItem.content = 'updating readers permission';
  readerItem.loading = true;
  const writerId = 'updateWriterMsg';
  const writerItem = generateFlashBarItem(writerId);
  writerItem.onDismiss = () =>
    setNotifications((items) =>
      items.filter((item) => item.id !== 'updateWriterMsg')
    );
  writerItem.type = 'success';
  writerItem.content = 'updating writers permission';
  writerItem.loading = true;
  const [displayReaderFlashBar, setDisplayReaderFlashBar] = useState(false);

  const [isCallUpdateReaderApiLoading, setIsCallUpdateReaderApiLoading] =
    useState(true);
  const [isCallUpdateReaderApiSuccess, setIsCallUpdateReaderApiSuccess] =
    useState(true);
  const [callUpdateReaderApiStatusCode, setCallUpdateReaderApiStatusCode] =
    useState(-1);
  const [callUpdateReaderApiErrMsg, setCallUpdateReaderApiErrMsg] =
    useState('');

  const [displaywriterFlashBar, setDisplaywriterFlashBar] = useState(false);

  const [isCallUpdatewriterApiLoading, setIsCallUpdatewriterApiLoading] =
    useState(true);
  const [isCallUpdatewriterApiSuccess, setIsCallUpdatewriterApiSuccess] =
    useState(true);
  const [callUpdatewriterApiStatusCode, setCallUpdatewriterApiStatusCode] =
    useState(-1);
  const [callUpdatewriterApiErrMsg, setCallUpdatewriterApiErrMsg] =
    useState('');

  const [newReader, setNewReader] = useState('');
  const [errorIndicatorReader, setErrorIndicatorReader] = useState('');
  const [errorIndicatorWriter, setErrorIndicatorWriter] = useState('');
  const [readerList, setReaderList] = useState<TokenGroupProps.Item[]>([]);
  const [newWriter, setNewWriter] = useState('');
  const [writerList, setWriterList] = useState<TokenGroupProps.Item[]>([]);

  useEffect(() => {
    if (newReader) {
      if (newReader.includes(' ')) {
        setErrorIndicatorReader('Cannot have space in between');
      } else {
        setErrorIndicatorReader('');
      }
    }
  }, [newReader]);

  useEffect(() => {
    if (newWriter) {
      if (newWriter.includes(' ')) {
        setErrorIndicatorWriter('Cannot have space in between');
      } else {
        setErrorIndicatorWriter('');
      }
    }
  }, [newWriter]);

  useEffect(() => {
    if (isCallUpdateReaderApiLoading) {
      readerItem.loading = true;
    } else {
      readerItem.loading = false;

      if (!isCallUpdateReaderApiSuccess) {
        readerItem.type = 'error';
        readerItem.content = `error ${callUpdateReaderApiStatusCode}: failed to grant read permission. ${callUpdateReaderApiErrMsg}`;
      } else {
        readerItem.type = 'success';
        readerItem.content = 'updating readers permission succeed';
      }
    }

    if (displayReaderFlashBar) {
      setNotifications([
        ...notifications.filter((item) => item.id !== 'updateReaderMsg'),
        readerItem,
      ]);
    }
  }, [
    isCallUpdateReaderApiLoading,
    isCallUpdateReaderApiSuccess,
    callUpdateReaderApiErrMsg,
    callUpdateReaderApiStatusCode,
  ]);

  const addReader = (newReader: string) => {
    const newItem: TokenGroupProps.Item = { label: newReader };
    if (!readerList.some((item) => item.label === newReader)) {
      setReaderList([...readerList, newItem]);
    }
  };

  const generateRequestBody = (action: Action) => {
    const newReadPermissions = [...readPermissions];
    const newWritePermissions = [...writePermissions];

    if (action == 'READ') {
      for (const i in readerList) {
        const label = readerList[i].label;
        if (label && !newReadPermissions.includes(label))
          newReadPermissions.push(label);
      }
    } else if (action == 'WRITE') {
      for (const i in writerList) {
        const label = writerList[i].label;
        if (label && !newWritePermissions.includes(label))
          newWritePermissions.push(label);
      }
    }

    const body: UpdatePermissionsCommandInput = {
      launchId: launchTaskDetail.launchId,
      readAcl: newReadPermissions,
      writeAcl: newWritePermissions,
    };

    return body;
  };

  const { isAuthenticated, token } = useAuthState();

  async function callGrantPermissionReadApi(
    body: UpdatePermissionsCommandInput
  ) {
    if (!token) {
      setIsCallUpdateReaderApiLoading(false);
      setIsCallUpdateReaderApiSuccess(false);
      throw new NoTokenError(); // Handle the missing token case appropriately
    }
    try {
      const response: UpdatePermissionsCommandOutput = await callApi(
        new UpdatePermissionsCommand({
          launchId: body.launchId,
          readAcl: body.readAcl,
          writeAcl: body.writeAcl,
        }),
        token
      );
      setIsCallUpdateReaderApiLoading(false);
      if (response.$metadata.httpStatusCode == 200) {
        setIsCallUpdateReaderApiSuccess(true);
        updateReadPermissions(body.readAcl);
      }
    } catch (err: any) {
      setIsCallUpdateReaderApiLoading(false);
      setIsCallUpdateReaderApiSuccess(false);
      setCallUpdateReaderApiErrMsg(err.message);
      setCallUpdateReaderApiStatusCode(err.statusCode);
    }
  }

  useEffect(() => {
    if (isCallUpdatewriterApiLoading) {
      writerItem.loading = true;
    } else {
      writerItem.loading = false;

      if (!isCallUpdatewriterApiSuccess) {
        writerItem.type = 'error';
        writerItem.content = `error ${callUpdatewriterApiStatusCode}: failed to grant write permission. ${callUpdatewriterApiErrMsg}`;
      } else {
        writerItem.type = 'success';
        writerItem.content = 'updating writers permission succeed';
      }
    }
    if (displaywriterFlashBar) {
      console.log('notification length' + notifications.length);
      setNotifications([
        ...notifications.filter((item) => item.id !== 'updateWriterMsg'),
        writerItem,
      ]);
    }
  }, [
    isCallUpdatewriterApiLoading,
    isCallUpdatewriterApiSuccess,
    callUpdatewriterApiErrMsg,
    callUpdatewriterApiStatusCode,
  ]);

  const addWriter = (newWriter: string) => {
    const newItem: TokenGroupProps.Item = { label: newWriter };
    if (!writerList.some((item) => item.label === newWriter)) {
      setWriterList([...writerList, newItem]);
    }
  };

  async function callGrantPermissionWriteApi(
    body: UpdatePermissionsCommandInput
  ) {
    if (!token) {
      setIsCallUpdatewriterApiLoading(false);
      setIsCallUpdatewriterApiSuccess(false);
      throw new NoTokenError(); // Handle the missing token case appropriately
    }

    try {
      const response: UpdatePermissionsCommandOutput = await callApi(
        new UpdatePermissionsCommand({
          launchId: body.launchId,
          readAcl: body.readAcl,
          writeAcl: body.writeAcl,
        }),
        token
      );
      setIsCallUpdatewriterApiLoading(false);
      if (response.$metadata.httpStatusCode == 200) {
        setIsCallUpdatewriterApiSuccess(true);

        updateWritePermissions(body.writeAcl);
      }
    } catch (err: any) {
      setIsCallUpdatewriterApiLoading(false);
      setIsCallUpdatewriterApiSuccess(false);
      setCallUpdatewriterApiErrMsg(err.message);
      setCallUpdatewriterApiStatusCode(err.statusCode);
    }
  }

  return (
    <div>
      <SpaceBetween direction='vertical' size='s'>
        <SpaceBetween direction='vertical' size='s'>
          <Box>
            <Flashbar
              items={notifications}
              i18nStrings={{
                ariaLabel: 'Notifications',
                notificationBarAriaLabel: 'View all notifications',
                notificationBarText: 'Notifications',
                errorIconAriaLabel: 'Error',
                warningIconAriaLabel: 'Warning',
                successIconAriaLabel: 'Success',
                infoIconAriaLabel: 'Info',
                inProgressIconAriaLabel: 'In progress',
              }}
              stackItems
            />
          </Box>
          <Container>
            <SpaceBetween size='s' direction='vertical'>
              <Container header={<Header>Assign read permissions</Header>}>
                <FormField
                  label='User alias or POSIX group'
                  errorText={errorIndicatorReader}
                >
                  <SpaceBetween direction='vertical' size='l'>
                    <ColumnLayout columns={2}>
                      <Input
                        value={newReader}
                        onChange={({ detail }) => {
                          setNewReader(detail.value);
                        }}
                      />
                      <Button
                        variant='primary'
                        disabled={errorIndicatorReader !== ''}
                        onClick={() => {
                          if (newReader != '') addReader(newReader);
                        }}
                      >
                        Add
                      </Button>
                    </ColumnLayout>
                  </SpaceBetween>
                </FormField>
              </Container>
              <Box display={readerList.length > 0 ? 'block' : 'none'}>
                <Container
                  header={
                    <Header
                      actions={
                        <SpaceBetween direction='horizontal' size='l'>
                          <Button
                            variant='primary'
                            onClick={() => {
                              setIsCallUpdateReaderApiLoading(true);
                              setDisplayReaderFlashBar(true);

                              const body = generateRequestBody('READ');
                              callGrantPermissionReadApi(body);
                              setReaderList([]);
                            }}
                          >
                            Save
                          </Button>
                          <Button onClick={() => setReaderList([])}>
                            Cancel
                          </Button>
                        </SpaceBetween>
                      }
                    >
                      Grant read permission
                    </Header>
                  }
                >
                  <TokenGroup
                    onDismiss={({ detail: { itemIndex } }) => {
                      setReaderList([
                        ...readerList.slice(0, itemIndex),
                        ...readerList.slice(itemIndex + 1),
                      ]);
                    }}
                    items={readerList}
                  />
                </Container>
              </Box>
            </SpaceBetween>
          </Container>
        </SpaceBetween>

        <SpaceBetween direction='vertical' size='s'>
          <Container>
            <SpaceBetween size='s' direction='vertical'>
              <Container header={<Header>Assign write permissions</Header>}>
                <FormField
                  label='User alias or POSIX group'
                  errorText={errorIndicatorWriter}
                >
                  <SpaceBetween direction='vertical' size='l'>
                    <ColumnLayout columns={2}>
                      <Input
                        value={newWriter}
                        onChange={({ detail }) => {
                          setNewWriter(detail.value);
                        }}
                      />
                      <Button
                        variant='primary'
                        disabled={errorIndicatorWriter !== ''}
                        onClick={() => {
                          if (newWriter != '') addWriter(newWriter);
                        }}
                      >
                        Add
                      </Button>
                    </ColumnLayout>
                  </SpaceBetween>
                </FormField>
              </Container>
              <Box display={writerList.length > 0 ? 'block' : 'none'}>
                <Container
                  header={
                    <Header
                      actions={
                        <SpaceBetween direction='horizontal' size='l'>
                          <Button
                            variant='primary'
                            onClick={() => {
                              setIsCallUpdatewriterApiLoading(true);
                              setDisplaywriterFlashBar(true);

                              const body = generateRequestBody('WRITE');
                              callGrantPermissionWriteApi(body);
                              setWriterList([]);
                            }}
                          >
                            Save
                          </Button>
                          <Button onClick={() => setWriterList([])}>
                            Cancel
                          </Button>
                        </SpaceBetween>
                      }
                    >
                      Grant write permission
                    </Header>
                  }
                >
                  <TokenGroup
                    onDismiss={({ detail: { itemIndex } }) => {
                      setWriterList([
                        ...writerList.slice(0, itemIndex),
                        ...writerList.slice(itemIndex + 1),
                      ]);
                    }}
                    items={writerList}
                  />
                </Container>
              </Box>
            </SpaceBetween>
          </Container>
        </SpaceBetween>
      </SpaceBetween>
    </div>
  );
};

export default ModificationView;
