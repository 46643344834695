import { PackageType } from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';
import React from 'react';
import { Select } from '@amzn/awsui-components-react';
import { SelectProps } from '@amzn/awsui-components-react/polaris/select/interfaces';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';

const map: Partial<Record<PackageType | '', string>> = {
  '': '-',
  [PackageType.API_REFERENCE]: 'API doc',
  [PackageType.CHECKLIST]: 'Checklist',
  [PackageType.CLOUDFORMATION_DOC_MODEL]: 'CloudFormation doc model',
  [PackageType.DOC_MODEL]: 'Documentation model',
  [PackageType.EXTERNAL_MODEL]: 'External model',
  [PackageType.SERVICE_MODEL]: 'Service model',
  [PackageType.OTHER]: 'Other',
  [PackageType.SERVICE_LANDING_PAGE]: 'Service landing page',
  [PackageType.SERVICE_SHARED_CONTENT]: 'Service shared content',
  [PackageType.STANDALONE_HELP_PANEL]: 'Standalone help panel',
  [PackageType.ZONBOOK_GUIDE]: 'Zonbook guide',
} as const;

export const packageTypeOptions = Object.entries(map).map(([value, label]) => ({
  value,
  label,
}));
type PackageTypeOption = { packageType?: PackageType | '' };
type PackageTypeSelectorProps = {
  editing?: boolean;
  item: PackageTypeOption;
  onChange?: NonCancelableEventHandler<SelectProps.ChangeDetail>;
};
export const PackageTypeSelector = ({
  item,
  editing,
  onChange,
}: PackageTypeSelectorProps) => (
  <Select
    disabled={!editing}
    ariaRequired={true}
    selectedOption={
      item.packageType
        ? {
            value: item.packageType as string,
            label: packageTypeOptions.find(
              ({ value }) => value === item.packageType
            )?.label,
          }
        : packageTypeOptions[0]
    }
    onChange={onChange}
    options={packageTypeOptions}
    invalid={item.packageType === ''}
  />
);
