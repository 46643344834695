import { useState } from 'react';
import {
  Modal,
  FormField,
  Input,
  Box,
  SpaceBetween,
  Button,
  Alert,
  FlashbarProps,
} from '@amzn/awsui-components-react/polaris';
import { useAuthState } from '../../../authentication/context';
import { callApi } from '../../../client';
import { generateFlashBarItem } from '../ui/editEditorViewSection';
import {
  DeleteLaunchTaskCommand,
  DeleteLaunchTaskCommandOutput,
} from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';
import { v4 } from 'uuid';
import { NoTokenError } from '../../../errors/NoTokenError';

interface DeleteProps {
  launchId: string;
  onClose: () => void;
  //result: boolean;
}

function refreshPage() {
  window.location.reload();
}

export const DeleteModal = ({ launchId, onClose }: DeleteProps) => {
  const [visible, setVisible] = useState(true);
  const [value, setValue] = useState('');
  const [deleted, setDeleted] = useState(true);
  const { isAuthenticated, token } = useAuthState();

  const id3 = v4();
  const item3 = generateFlashBarItem(id3);
  item3.onDismiss = () =>
    setStatusItems((items) => items.filter((item) => item.id !== id3));
  item3.type = 'error';
  item3.content = `delete ${launchId} opertion failed`;
  item3.loading = false;

  const [statusItems, setStatusItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([item3]);

  async function callDeleteLaunchTaskAPI() {
    if (!token) throw new NoTokenError();

    try {
      const response: DeleteLaunchTaskCommandOutput = await callApi(
        new DeleteLaunchTaskCommand({
          launchId: launchId,
        }),
        token
      );

      if (response.$metadata.httpStatusCode == 200) {
        console.log(`delete ${launchId} successful`);
        refreshPage();
        setDeleted(true);
      }
    } catch (err) {
      setDeleted(false);
    }
  }

  // async function callDeleteAPI() {
  //   if (isAuthenticated || isDevDesk()) {
  //     setVisible(true);
  //     const invokeUrl = getApiInvokeUrl() + `/launches/${launchId}`;
  //     const requestOptions: any = {
  //       headers: {
  //         'x-lambda-authorizer-token': token,
  //         'Content-Type': 'application/json',
  //       },
  //       method: 'DELETE',
  //     };

  //     try {
  //       let response;
  //       response = await fetch(invokeUrl, requestOptions);
  //       if (response.status === 204) {
  //         setDeleted(true);
  //       }
  //     } catch (err) {
  //       console.log('Error with FETCH', err);
  //     }
  //   }
  // }
  const handleCancel = () => {
    setVisible(false);
    onClose();
  };

  function isConfirmed(text: string) {
    return text.toLowerCase() === 'confirm';
  }

  // Function to handle delete button click
  const handleDelete = () => {
    callDeleteLaunchTaskAPI();
    //setDeleted(true)
    setVisible(deleted); // Close the modal after the action is completed
  };

  return (
    <div>
      <SpaceBetween direction='vertical' size='m'>
        <Modal
          onDismiss={handleCancel}
          visible={visible}
          footer={
            <Box float='right'>
              <SpaceBetween direction='horizontal' size='xs'>
                <Button variant='link' onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  disabled={!isConfirmed(value)}
                  variant='primary'
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </SpaceBetween>
            </Box>
          }
          header='Delete Confirmation'
        >
          <FormField
            label='Are you sure you want to delete this Launch with ID?'
            description=''
          >
            <Input value={launchId} readOnly />
          </FormField>
          <Alert statusIconAriaLabel='Warning' type='warning'>
            Proceeding with this action will mark this launch item as deleted,
            and assigned writers will not be able to see or receive
            notifications for this task
          </Alert>
          <span>
            To avoid accidental deletion, we ask you to provide additional
            written consent
          </span>
          <br></br>
          <span>
            <b>Type confirm this deletion, type "confirm"</b>
          </span>

          <Input
            onChange={({ detail }) => setValue(detail.value)}
            value={value}
          />
        </Modal>
      </SpaceBetween>
    </div>
  );
};
