import { ReactNode } from 'react';
import { BadgeProps } from '@amzn/awsui-components-react';
import {
  LaunchTask,
  WorkStatus,
  BuildRequestStatus,
  AutoSuggestPackageNameOutput,
} from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';

export interface NodeChildren {
  children: ReactNode;
}

export interface FetchedLaunchData {
  data: LaunchTask[];
  loading: boolean;
}

export interface EmptyStateProps {
  title: string;
  subtitle: string;
  action: ReactNode;
}

export interface Breadcrumb {
  text: string;
  href: string;
}

export const workStatusMessages: Record<
  WorkStatus,
  { text: string; color: BadgeProps['color'] }
> = {
  TASK_RECEIVED: { text: 'Task Received', color: 'grey' },
  PACKAGE_SUBMITTED_BY_WRITER: {
    text: 'Package Information Updated',
    color: 'blue',
  },
  PACKAGE_BUILT: { text: 'Package Build Request Succeeded', color: 'green' },
  PACKAGE_BUILD_FAILED: { text: 'Package Build Request Failed', color: 'red' },
  MANIFEST_BUILT: { text: 'Manifest Built', color: 'blue' },
  REVIEW_APPROVED: { text: 'Ready to Publish', color: 'green' },
  REVIEW_REJECTED: { text: 'Update Package Information', color: 'red' },
  PUBLISH_SCHEDULED: { text: 'Publishing Scheduled', color: 'green' },
  DELETED: { text: 'Deleted', color: 'grey' },
  VENUE_CHANGED: { text: 'Venue Changed', color: 'green' },
  RESOLVED: { text: 'Resolved', color: 'green' },
} as const;

export enum TranslatedWorkStatus {
  TASK_RECEIVED = 'Task Received',
  PACKAGE_SUBMITTED_BY_WRITER = 'Package Information Updated',
  PACKAGE_BUILT = 'Package Build Request Succeeded',
  MANIFEST_BUILT = 'Manifest Built',
  PACKAGE_BUILD_FAILED = 'Package Build Request Failed',
  REVIEW_APPROVED = 'Ready to Publish',
  REVIEW_REJECTED = 'Update Package Information',
  PUBLISH_SCHEDULED = 'Publishing Scheduled',
  VENUE_CHANGED = 'Venue Changed',
  DELETED = 'Deleted',
  RESOLVED = 'Resolved',
}

export enum TraslatedDocumentStatus {
  GREEN = 'Green',
  YELLOW = 'Yellow',
  RED = 'Red',
}

export const buildStatusMessages: Record<BuildRequestStatus, string> = {
  SUBMITTING: '⚡ Submitting your build...',
  SUBMIT_FAILED: '🚨 Failed to submit build',
  WAITING: '⚡ Waiting...',
  PRUNABLE: '🤌 Your build can be pruned',
  PENDING: '⚡ Build in progress...',
  DEFERRED: '🚧 Build deferred',
  BLOCKED: '⛔ Build blocked!',
  IN_PROGRESS: '⚡ Build in progress...',
  BLOCKED_ON_SERIALIZATION: '⛔ Build blocked!',
  CANCELED: '🛑 Build cancelled',
  FAILED: '🔥 Build failed 🔥',
  DONE: 'Build succeeded! 🎉',
  UNKNOWN: '🤷 Awaiting status...',
  SUBMIT_SKIPPED: 'Updated 🎉',
} as const;

export type PermissionsGroups = 'readonly-user' | 'info-editor';

export type AutoSuggestStatus = 'loading' | 'finished' | 'error' | 'pending';

export type Suggestions = AutoSuggestPackageNameOutput['suggestions'];
