import {
  FormField,
  Input,
  ColumnLayout,
  Grid,
  Spinner,
  Badge,
} from '@amzn/awsui-components-react/polaris';
import { useEffect, useState } from 'react';
import { LaunchTask } from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';

interface BuddyLaunchProps {
  buddyLaunch: LaunchTask;
  onDeleted?: () => void;
  LaunchTaskDetail: LaunchTask;
}

type BuddyLaunchesProps = {
  buddyLaunches: LaunchTask[];
  onCountChange?: (count: number) => void;
  launchTaskDetail: LaunchTask;
  buddyLaunchesLoading: boolean;
  buddyLaunchFetchSuccess: boolean;
};

const BuddyLaunch = ({ LaunchTaskDetail, buddyLaunch }: BuddyLaunchProps) => {
  const [newbuddyLaunch, setNewBuddyLaunch] = useState(buddyLaunch);

  useEffect(() => {
    setNewBuddyLaunch(buddyLaunch);
  }, [buddyLaunch]);

  return (
    <div>
      <ColumnLayout columns={3}>
        <div>
          <FormField
            // description='Buddy launch ticket ID.'
            label='Buddy Launch Id'
          >
            <Input disabled={true} value={newbuddyLaunch.launchId} />
          </FormField>
        </div>
        <div>
          <FormField
            // description='Service name of your buddy launch.'
            label='Buddy Launch Name'
          >
            <Input disabled={true} value={newbuddyLaunch.name} />
          </FormField>
        </div>
        <div>
          <FormField
            // description='Writer assigned to this launch.'
            label='Assigned Writer'
          >
            <Input
              disabled={true}
              value={newbuddyLaunch?.writerAssignment?.alias ?? ''}
            />
          </FormField>
        </div>
      </ColumnLayout>
    </div>
  );
};

export const BuddyLaunches = ({
  buddyLaunches,
  launchTaskDetail,
  buddyLaunchesLoading,
  buddyLaunchFetchSuccess,
}: BuddyLaunchesProps) => {
  const [launches, setLaunches] = useState(buddyLaunches);

  useEffect(() => {
    setLaunches(buddyLaunches);
  }, [buddyLaunches]);

  if (buddyLaunchesLoading) {
    return <Spinner size='large' />;
  }

  if (!buddyLaunchFetchSuccess) {
    return <Badge color='red'>Error</Badge>;
  }

  return (
    <div>
      {launches.map((buddyLaunch, i) => (
        <BuddyLaunch
          key={i}
          LaunchTaskDetail={launchTaskDetail}
          buddyLaunch={buddyLaunch}
        />
      ))}
    </div>
  );
};

export default BuddyLaunches;
